<template>
	<div class="">
		<div style=" width: 100%; display: inline-flex;flex-direction: column; margin-bottom: 20px;">
			<div class="" style="font-size: 24px; font-weight: 600; border-left: 5px solid #333;color: #333;padding-left: 12px; ">
				教研现场
			</div>
		</div>
		
		<div class="" style="width: 100%; margin:10px 0 20px 0; display: flex;flex-direction: column;">
			<div class="" style=" width: 100%; height: 430px; background-color: #fff; position: relative; ">
				<div id="mse">
					<!-- 幻灯片图片区 -->
				</div>
				<div v-if="show" class="ppt_chapters_scroll_y_box" style="z-index: 12; background-color: #eee; padding: 5px 15px; box-sizing: border-box ;box-shadow: 5px 5px 10px rgba(0, 0, 0, .3);position: absolute;right: -10px; bottom: -15px; width: 316px; height: 360px; display: inline-flex;flex-direction: column; ">
					<div class="ppt_chapters_scroll_y" style=" width: 100%;  overflow-y: scroll; padding-right: 15px; box-sizing: border-box;">
						
						<!-- 
							<div class="chapter-item" :class="{'actived':listData && listData.current && listData.current.id == item.id}" v-for="(item,index) in listData.list" @click="getVideo(item)">
								<div class="title" v-if="item.discuss_date">
									{{item.discuss_date}}
								</div>
								<div class="sub_title" v-if="item.title">
									{{item.title}}
								</div>
							</div> 
						-->
						
						<!-- 
						<div class="chapter-item">
							<div class="title">
								2023.12.23 20:00
							</div>
							<div class="sub_title">
								任课教师金融素养与投资学理论教学
							</div>
							<div style="display: flex; flex-direction: column; margin-top: 10px;">
								<img style="width: 150px;" src="../../assets/images/qrcode_20231222.jpg" />
								<div style="font-size: 15px; margin-top: 12px; color: #666; ">扫码预约</div>
							</div>
						</div> 
						-->
						
					</div>
				</div>
		
			</div>
			<div class="" style="width: 100%; height: 50px; background-color: #333;display: flex;">
				<div v-if="listData && listData.current" class="" style="flex: 1; display: flex;align-items: center; color: #fff; padding: 0 20px; box-sizing: border-box;">
					<i class="iconfont icon-shipin" style="font-size: 20px;margin-right: 5px;"></i> 直播主题：{{listData.current.title}}
				</div>
				<div @click="changeShow" class="" style="width: 140px; height: 100%; background-color: #CE671B; color: #fff; font-size: 20px; display: flex;justify-content: center;align-items: center;cursor:  pointer;">
					<i class="iconfont icon-24gf-playCircle" style="margin-right: 5px;font-size: 18px; "></i>直播预告
				</div>
			</div>
		</div>
		
		<div style=" width: 100%; display: inline-flex;flex-direction: column; margin-bottom: 20px;">
			<div class="" style="font-size: 20px; font-weight: 600; color: #333;padding-left: 12px; margin-bottom: 10px;">
				<i class="iconfont icon-shipin" style="font-size: 24px; font-weight: normal;"></i> 直播回放
			</div>
		</div>
		
		
		<div class="video-items">
			<div class="video-item" @click="getVideo(item)"  :class="{'actived': listData && listData.current && listData.current.id == item.id}" v-for="(item,index) in listData.list">
				<div class="video-length">
					<i class="el-icon-caret-right video-icon"></i>
					<div class="">
						{{item.discuss_date}}
					</div>
				</div>
				<div class="video-title">
					{{item.title}}
				</div>
			</div>
		</div>
		
	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";
	import Player from 'xgplayer'
	import 'xgplayer/dist/index.min.css'

	export default {
		props: {
			myid: String
		},
		data() {
			return {
				id: this.myid,
				listData: {
					current: {},
					list: [],
				},
				show: false,
				player: null,
			};
		},
		created() {
			//document.title = "教学研讨 - 数字教研室 - 机工教师特训营";
			this.getList();
		},
		// watch: {
		// 	myid(newval) {
		// 		this.id = newval;
		// 	},
		// },
		methods: {
			play() {
				var that = this;
				if (!that.player) {
					var player = new Player({
						"el": document.getElementById('mse'),
						"url": that.listData.current.video_url,
						'fluid': true,
						'volume': 0.3,
						'videoInit': true,
						'playsinline': true,
						'playbackRate': [0.5, 0.75, 1, 1.5, 2],
						'defaultPlaybackRate': 1,
						'x5-video-player-type': 'h5',
						'x5-video-orientation': 'portraint',
						"lang": 'zh-cn',
						'commonStyle': {
							'playedColor': '#C43D31',
							'volumeColor': '#C43D31'
						}
					});
					that.player = player;
				} else {
					that.player.src = that.listData.current.video_url;
					that.player.autoplay = true;
				}
			},
			changeShow() {
				this.show = !this.show;
			},
			getList() {
				var that = this;
				var url = apiUrl.digital_office_discuss_list_get;
				this.Ajax(url, { id: this.id }, (data) => {
					that.listData.list = data;
					that.getVideo(data[0]);
					console.log(that.listData.list)
				});
			},
			getVideo(item) {
				var that = this;
				var url = apiUrl.digital_office_discuss_detail_get;
				this.Ajax(url, { id: item.id }, (data) => {
					that.listData.current = data;
					that.listData.list.forEach((item, index) => {
						if (item.id == data.id) {
							that.listData.current.index = index + 1;
						}
					})
					this.play();
				});
			},
			nextPage() {
				if (this.listData.current.index >= this.listData.count) {
					this.$message({
						message: '已经是最后一页',
						type: 'warning',
						offset: 300,
						duration: 1500
					});
					return;
				}
				this.getVideo(this.listData.list[this.listData.current.index]);
			},
			lastPage() {
				if (this.listData.current.index <= 1) {
					this.$message({
						message: '已经是第一页',
						type: 'warning',
						offset: 300,
						duration: 1500
					});
					return;
				}
				this.getVideo(this.listData.list[this.listData.current.index - 2])
			}
		},
	};
</script>

<style lang="scss" scoped>
	
	.ppt_chapters_scroll_y {
		padding-bottom: 100px !important;
	}
	
	.ppt_chapters_scroll_y::-webkit-scrollbar {
		width: 3px;
	}
	
	.ppt_chapters_scroll_y::-webkit-scrollbar-thumb {
		height: 50px;
		background-color: #3DD2BC;
		border-radius: 6px;
	}
	
	.xgplayer .xg-options-list li:hover,
	.xgplayer .xg-options-list li.selected {
		color: #C43D31;
		opacity: 1;
	}
	
	.ppt_chapters_scroll_y::-webkit-scrollbar-thumb {
		height: 50px;
		background-color: #3DD2BC;
		border-radius: 6px;
	}
	
	.chapter-item {
		width: 100%;
		display: flex;
		flex-direction: column;
		font-size: 15px;
		padding: 10px 0;
		border-bottom: 1px solid #ccc;
		box-sizing: border-box;
		cursor: pointer;
	
		.title {
			font-weight: 600;
			color: #333;
		}
	
		.sub_title {
			margin-top: 5px;
			color: #999;
		}
	
		&:hover,
		&.actived {
			background-color: #E4E4E4;
	
			.title {
				color: #333;
			}
	
			.sub_title {
				color: #5d5d5d;
			}
		}
	
		&:last-child {
			border-bottom: 0;
		}
	}
	
	.video-items {
		width: 100%;
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
	
		.video-item {
			width: 180px;
			height: 70px;
			border: 1px solid #eee;
			background-color: #fff;
			padding: 10px 15px;
			box-sizing: border-box;
			color: #999;
			font-size: 15px;
			margin: 0 15px 15px 0;
			cursor: pointer;
	
			&:nth-child(4n) {
				margin-right: 0;
			}
	
			&:hover,&.actived {
				background: linear-gradient(to right,#C63E31,#FB9226) !important;
				color: #fff;
				box-shadow: 3px 5px 3px rgba(0, 0, 0, 0.3);
			}
	
			.video-length {
				display: flex;
	
				.video-icon {
					margin-left: -20px;
					margin-right: 6px;
				}
			}
	
			.video-title {
				margin-top: 5px;
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 2;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
	
	
</style>