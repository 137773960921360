<template>
	<div class="page-main">

		<!-- <div class="date-panel">
			<div class="year">2024</div>
			<div class="month"
				:class="{'fill':item <13 ,'disabled':item<4 || item == 12,'border-b-none':item == 12 }"
				v-for="(item,index) of 12"
				@click="scrollToAnchor(item)">
				<a :href="'#month'+item">{{item}}月</a>
			</div>
		</div> -->
		
		
		<div class="date-panel">
			<div class="year">2024</div>
			<div class="month"
				:class="{'fill':item > 13,'disabled':item<4 || item == 12,'border-r':item <12 && item % 2 ==1 ,'border-b-none':item == 13}"
				v-for="(item,index) of 12"
				@click="scrollToAnchor(item)">
				<a :href="'#month'+item">{{item}}月</a>
			</div>
			<div class="year">2025</div>
			<div class="year" style="font-size: 12px; border-bottom: none;">敬请期待 ···</div>
		</div>
		
		<div v-if="productData.list" :id="'month'+item.month" v-for="(item,index) in productData.list"  @click="toDetail(item)"  style=" cursor: pointer;width: 100%;display:flex; flex-direction: column; position: relative; justify-content: center; align-items: center; position: relative;">
			<!-- <div v-if="hover_index!=1" style="display: block;width: 100%; min-height: 100%;background-color: rgba(187, 187, 187, 0.4); position: absolute; top: 0; left: 0;z-index:2; cursor: pointer;"></div> -->			
			<img :src='item.img_url' style="width: 100%;" />
			<div style="width: 1200px; display: inline-flex;position: absolute;top: 0;">
				<div style="width: 52px; height: 58px; display: inline-flex; flex-direction: column; justify-content: center;align-items: center; background-color:#F6892E ; color: #fff; font-weight: 600;  box-sizing: border-box;">
					<div style="font-size: 22px; border-bottom: 2px solid #fff;">{{item.month}}月</div>
					<div style="font-size: 15px;">{{item.year}}</div>
				</div>
			</div>
		</div>
		

	</div>
</template>

<script>
	import apiUrl from "../../js/apiUrl.js";
	import comm from "../../js/comm.js";


	export default {
		data() {
			return {
				list: [{
					year: '2024',
					month: '12',
					img_url: ''
				},{
					year: '2024',
					month: '11',
					img_url: ''
				},{
					year: '2024',
					month: '10',
					img_url: ''
				},{
					year: '2024',
					month: '9',
					img_url: ''
				},{
					year: '2024',
					month: '8',
					img_url: ''
				},{
					year: '2024',
					month: '7',
					img_url: ''
				},{
					year: '2024',
					month: '6',
					img_url: ''
				},{
					year: '2024',
					month: '5',
					img_url: ''
				}, {
					year: '2024',
					month: '4',
					img_url: ''
				}, {
					year: '2024',
					month: '3',
					img_url: ''
				}, {
					year: '2024',
					month: '2',
					img_url: ''
				}, {
					year: '2024',
					month: '1',
					img_url: ''
				}, ],
				hover_index: null,
				search: {
					page_index: 1,
					page_size: 100,
					order_by: ''
				},
				productData: {
					total: 0,
					count: 0,
					list: []
				}
			};
		},
		created() {
			document.title = "教师特训营 - 机工教师特训营";
			this.productDataGet(1);
		},
		methods: {
			hoverClick(index){
				this.hover_index = index;
			},
			outClick(){
				this.hover_index = null;
			},
			toDetail(item){
				if(!item.id){
					this.$alert('特训营正在紧张筹备中，敬请期待······', '温馨提示', {
					          confirmButtonText: '确定'
					  });
					return;
				}
				this.GoToDetail(item.product_type,item.id);
			},
			productDataGet(index) {
				var that = this;
				if (index <= 1) {
					that.search.page_index = 1;
					that.productData.total = 1;
				}
				if (that.search.page_index > that.productData.total) {
					return;
				}
				that.search.page_index = index;
				var url = apiUrl.tt_camp_list_get;
				this.Ajax(url, this.search, (data) => {
					that.productData.list = data.list;
					that.productData.total = data.total;
					that.productData.count = data.count;
				});
			},
			scrollToAnchor(item) {
				window.location.hash = 'month'+item.month;
			}
		},
	};
</script>


<style lang="scss" scoped>
	.page-main {
		width: 100%;
		min-height: 100vh;
		background: #fff;
		display: flex;
		flex-direction: column;
		align-items: center;
		position: relative;
		
		.opacity-mask{
			//opacity: 0.7;
			//filter: grayscale(100%);
		}
		.date-panel {
			width: 80px;
			display: flex;
			flex-wrap: wrap;
			position: fixed;
			top: 120px;
			right: 65px;
			background-color: #fff;
			z-index: 99;
			border-radius: 10px;
			box-shadow: 0 5px 30px 0 rgba(46,49,72,.06);

			.year {
				font-size: 18px;
				font-weight: 600;
				box-sizing: border-box;
				width: 100%;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-bottom: 1px solid #eee;
			}

			.month {
				font-size: 14px;
				width: 50%;
				height: 40px;
				display: flex;
				justify-content: center;
				align-items: center;
				border-bottom: 1px solid #eee;
				cursor: pointer;
				
				a{
					color: #666;	
				}

				&.border-r {
					border-right: 1px solid #eee;
				}

				&.border-b-none {
					border-bottom: none !important;
					border-radius: 0 0 10px 10px;
				}

				&.fill {
					font-size: 18px !important;
					width: 100% !important;
				}
				
				&.disabled{
					a{
						color:rgba(0, 0, 0, 0.3) !important;
					}
				}

				&:not(.disabled):hover,
				&.actived {
					color: #F28B3B !important;
					background-color: #eee;
					a{
					color: #F28B3B !important;	
					}
				}
			}
		}
	}
</style>